<template>
  <div class="index-container">
    <div class="container-header">
      <Header></Header>
    </div>
    <div class="container-con">
      <router-view/>
    </div>
    <div class="container-footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'

export default {
  name: 'index',
  components: { Header, Footer }
}
</script>

<style>
@media screen and (max-width: 900px) {
  .container-con {
    margin-top: 1rem;
  }
}

</style>
