<template>
  <div class="flex-col merchants-container">
    <!-- 蒙层 -->
    <!-- <div class="mask-layer"></div> -->
    <div class="flex-col mask-out">
      <div class="container-img">
        <img class="image" src="@/assets/images/merchants-text.png" alt="">
      </div>
      <div class="flex-col text-box">
        <h1>邦妮星选</h1>
        <h2>诚招合作伙伴</h2>
        <h3>邦妮星选平台是基于S2B2C模式</h3>
        <h3>工厂直对消费者的一站式全品类优质供应链批发平台</h3>
      </div>
      <!-- 招商信息 -->
      <div class="flex-row merchants-list">
        <div class="flex-col merchants-item" v-for="mItem in merchantsList" :key="mItem.id">
          <div class="flex-col item-box">
            <div class="flex-col item-title">
              <p v-for="tItem in mItem.title" :key="tItem">{{tItem}}</p>
            </div>
            <div class="flex-content item-image">
              <img :src="require(`@/assets/icons/merchants/${mItem.image}.png`)" alt="">
            </div>
            <div class="flex-col item-desc">
              <p  v-for="dItem in mItem.text" :key="dItem">{{dItem}}</p>
            </div>
          </div>
          <div class="item-box-mobile">
            <div class="flex-content item-image">
              <img :src="require(`@/assets/icons/merchants/${mItem.image}.png`)" alt="">
            </div>
            <div class="mobile-box">
              <div class="flex-col item-title">
                <p v-for="tItem in mItem.title" :key="tItem">{{tItem}}</p>
              </div>
              <div class="flex-col item-desc">
                <p  v-for="dItem in mItem.text" :key="dItem">{{dItem}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 加入按钮 -->
      <div class="flex-content enter-btn" v-if="false">
        <p>我要加入</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Merchants',
  data () {
    return {
      merchantsList: [
        {
          id: 1,
          image: 'dingdanshuliang',
          title: ['订单数量', '持续稳定'],
          text: ['分销商>11万', '垂直爆品月销量>10万', '稳定的生产计划']
        },
        {
          id: 2,
          image: 'quanpinlei',
          title: ['全品类', '商品招商'],
          text: ['细分品类', '锁定长期合作伙伴']
        },
        {
          id: 3,
          image: 'xuankuanhezuo',
          title: ['选款合作', '零压力'],
          text: ['专业测款团队', '提供强有力的数据支撑', '优化款式']
        },
        {
          id: 4,
          image: 'tuihuanhuo',
          title: ['订单制', '风险可控'],
          text: ['除质量问题外无退换货', '合作省心']
        },
        {
          id: 5,
          image: 'hezuomoshi',
          title: ['合作模式', '生态化'],
          text: ['面料合作开发', '稳定的结款方式', '战略深度入股合作均可']
        }
      ]
    }
  }
}
</script>

<style lang='less' scoped>
@media screen and(max-width: 900px){
  .merchants-container {
    width: 100%;
    // height: 17rem;
    background-image: url(../../../assets/images/merchants-bg1.png);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    .mask-out {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .container-img {
        display: none;
      }
      .text-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff1d2;
        h1 {
          font-size: 30px;
        }
        h2 {
          font-size: 24px;
          font-weight: 500;
          margin-top: 20px;
        }
        h3 {
          font-size: 14px;
          font-weight: 500;
          margin-top: 10px;
        }
        h3:last-child{
          margin: 0;
        }
      }
      .merchants-list {
        width: 100%;
        flex-direction: column;
        // background: linear-gradient(270deg,#ffce91,#ffefd9);
        border-radius: 10px;
        padding: .2rem;
        box-sizing: border-box;
        margin-top: 20px;
        padding: 0 10px;
        .merchants-item:first-child{
          margin: 0;
        }
        .merchants-item {
          width: 100%;
          background: #FFFFFF;
          border-radius: 10px;
          margin-top: 10px;
          min-height: 2rem;
          .item-box {
            display: none;
          }
          .item-box-mobile {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: .1rem;
            .item-image {
              width: 70px;
              min-width: 70px;
              height: 70px;
            }
            .mobile-box {
              margin-left: 10px;
              .item-title {
                flex-direction: row;
                font-size: 20px;
                font-weight: bold;
                p:nth-child(2) {
                  margin-left: 10px;
                }
              }
              .item-desc {
                font-size: 14px;
                p {
                  margin-top: 5px;
                }
              }
            }
          }
        }
      }
      .enter-btn {
        margin-top: .3rem;
        font-size: 22px;
        color: #FFFFFF;
        background: #c90010;
        border-radius: 50px;
        padding: .1rem .5rem;
        margin-bottom: .3rem;
      }
    }

  }
}
@media screen and(min-width: 900px) {
  .merchants-container {
    width: 100%;
    height: 1107px;
    background-image: url(../../../assets/images/merchants-bg.jpg);
    background-size: cover;
    align-items: center;
    position: relative;
    z-index: 1;
    .mask-layer {
      width: 100%;
      height: 100%;
      background-color: rgba(59, 67, 71, 0.9);
      position: absolute;
      z-index: 2;
    }
    .mask-out {
      z-index: 3;
      width: 100%;
      align-items: center;
        .container-img {
        width: 1100px;
        height: 148px;
        margin-top: 138px;
        z-index: 3;
      }
      .text-box {
        display: none;
      }
      .merchants-list {
        width: 80%;
        max-width: 1380px;
        // min-width: 1300px;
        height: 449px;
        background-color: #baa790;
        border-radius: 25px;
        margin-top: 118px;
        align-items: center;
        flex-wrap: nowrap;
        padding: 0 10px;
        .merchants-item {
          width: 20%;
          align-items: center;
          .item-image {
            width: 60px;
            height: 60px;
            margin-top: 44px;
          }
          .item-box-mobile {
            display: none;
          }
          .item-box:hover {
            box-shadow: 0 0 15px rgba(0, 0, 0, .25);
            transform: scale(1.05);
          }
          .item-box {
            cursor: pointer;
            width: calc(100% - 35px);
            height: 360px;
            background: #FFFFFF;
            border-radius: 20px;
            align-items: center;
            transition: all .2s linear;
            .item-title {
              margin-top: 50px;
              align-items: center;
              p {
                font-size: 24px;
                font-weight: bold;
                letter-spacing: 4px;
              }
            }
            .item-desc {
              margin-top: 45px;
              align-items: center;
              p {
                margin-top: 5px;
                font-weight: bold;
                letter-spacing: 1px;
                font-size: 12px;
              }
            }
          }
        }
      }
      .enter-btn {
        cursor: pointer;
        width: 274px;
        height: 69px;
        background: #c90010;
        border-radius: 40px;
        margin-top: 114px;
        p {
          font-size: 30px;
          font-weight: bold;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
